


import {
    defineStore
} from 'pinia'

import {
    getDetailHomeData,
    getUserData
} from "@/api/modules/companyDetail_api.js";
// 过滤图片
import filterProductImg from '@/filters/filterProductImg.js'
import { LinkMeData } from "@/api/modules/companyDetail_api.js";


// 详情页
export const useDetail = defineStore('detail', {
    state: () => {
        return {
            company: {}, // 公司信息
            goods: [], // 公司商品
            user: [], // 公司的联系人  
            loading: false,//loading
        }
    },
    actions: {
        // 初始化 公司信息和数据
        async initCompanyInfo(companyid) {
            this.loading = true
            const token = localStorage.getItem('token')
            try {
                let res = await getDetailHomeData({
                    id: companyid.id,
                    token: token ? token : ''
                });
                let {
                    status,
                    data
                } = res;
                if (status == 1) {
                    let { gallery } = data.company
                    if (gallery && gallery.length) {
                        data.goods.forEach((v, index) => {
                            gallery.forEach((item, i) => {
                                if (index == i) {
                                    data.goods[index].goods_img = gallery[index]['图片'] ? 'https://cdn.gangxinbao.cn/' + gallery[index]['图片'] : ''
                                }
                            })
                        })
                    } else {
                        data.goods.forEach(v => {
                            v.goods_img = filterProductImg(v.name)
                        })
                    }
                    // 请求成功
                    this.company = data.company
                    this.goods = data.goods
                    this.user = data.user
                    this.initLinkMan({ id: companyid }) // 获取业务联系的接口
                }
            } catch (error) {
                console.log(error);
            } finally {
                this.loading = false
            }
        },
        async initLinkMan(params) {
            let res = await getUserData(params)
            if (res.status == 1) {
                this.user = res.data
            }
        }
    }
})

// 业务联系
export const useLinkMe = defineStore('linkMan', {
    state: () => {
        return {
            linkMeList: [], // 业务联系
        }
    },
    actions: {
        async getLinkMeData(params) {
            let res = await LinkMeData(params)
            let { status, data } = res
            if (status == 1) {
                this.linkMeList = data.user
            }
        }

    }
})
